
export default {
  name: 'Checkbox',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value);
    },
  },
};
