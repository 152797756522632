
export default {
  name: 'Alert',
  props: {
    type: {
      type: String,
      required: false,
      default: 'error',
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    style() {
      switch (this.type) {
        case 'error':
        default:
          return {
            borderColor: '#d92001',
            backgroundColor: 'rgba(168, 24, 0, 0.1)',
          };
      }
    },
  },
};
