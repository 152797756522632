
export default {
  name: 'Tooltip',
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$tippy(this.$el, {
      content: this.content,
      animation: 'shift-away',
      placement: 'top',
      arrow: true,
      theme: 'light',
    });
  },
};
