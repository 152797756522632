
import get from 'lodash/get';
import to from '~/utils/to';

export default {
  name: 'Form',
  emits: ['loggedIn'],
  data() {
    return {
      form: {
        email: '',
        reservationNumber: '',
        remember: false,
      },
    };
  },
  computed: {
    forgotReservationLink() {
      const route = `${this.slug('customer.resend-slug')}/${this.slug('customer.reservation_confirmation-slug')}`;

      return `${this.$paths.parseRoute(route)}/`;
    },
  },
  methods: {
    slug(keyPath) {
      return this.$i18n(keyPath).replaceAll(' ', '-').toLowerCase();
    },
    formatTimeFromNow(dateString) {
      const now = new Date();
      const date = new Date(dateString);
      const timezoneOffset = date.getTimezoneOffset();
      const adjustedNow = new Date(now.getTime() + timezoneOffset * 60 * 1000);
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60 * 1000);
      const diff = Math.abs(adjustedDate - adjustedNow);
      const minutes = Math.floor(diff / (1000 * 60));
      if (minutes < 60) {
        return `${minutes} ${this.$i18n(`general.minute${minutes !== 1 ? 's' : ''}`)}`;
      }
      if (minutes < 24 * 60) {
        const hours = Math.floor(minutes / 60);
        return `${hours} ${this.$i18n(`reservation.hour${hours !== 1 ? 's' : ''}`)}`;
      }
      const days = Math.floor(minutes / (24 * 60));
      return `${days} ${this.$i18n(`general.day${days !== 1 ? 's' : ''}`)}`;
    },
    async submitAndRedirect() {
      const [error] = await to(
        this.$auth.login({
          email: this.form.email,
          reservation_number: this.form.reservationNumber,
          remember: this.form.remember,
          language_id: this.$store.state.language.id,
        }),
      );

      if (error) {
        const status = get(error, 'response.status', null);
        const message = get(error, 'response.data.message', null);

        if (status === 401) {
          const key =
            {
              NOT_ACTIVE: 'customer.login-not-active',
              IP_IS_TEMPORARILY_BLOCKED: 'customer.login-ip-blocked',
              AUTH_TOO_MANY_ATTEMPTS_30_MINUTES: 'customer.login-too-many-attempts-30-minutes',
              AUTH_TOO_MANY_ATTEMPTS_24_HOURS: 'customer.login-too-many-attempts-24-hours',
            }[message] ?? 'customer.login-failed';

          const replacements =
            {
              IP_IS_TEMPORARILY_BLOCKED: {
                last_hour: get(error, 'response.data.last_hour', null),
                until: this.formatTimeFromNow(get(error, 'response.data.meta.attempts.until')),
              },
              AUTH_TOO_MANY_ATTEMPTS_30_MINUTES: {
                last_hour: get(error, 'response.data.meta.attempts.last_hour', null),
              },
              AUTH_TOO_MANY_ATTEMPTS_24_HOURS: {
                last_day: get(error, 'response.data.meta.attempts.last_day', null),
              },
            }[message] ?? {};

          this.$store.commit('message/addAlert', {
            title: null,
            text: this.$i18n(key, replacements),
            type: 'error',
          });

          return;
        }

        this.$helpers.errorHandler(error);
        return;
      }

      this.$emit('loggedIn');
    },
    async login() {
      this.$refs.form.reset();
      this.$store.commit('message/resetAll');

      const [err, success] = await to(this.$refs.form.validate());

      if (err || !success) return;

      await this.submitAndRedirect();
    },
  },
};
