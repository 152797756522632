
export default {
  name: 'BtnFilled',
  props: {
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tag() {
      return this.href ? 'a' : 'button';
    },
    attrs() {
      return this.href
        ? {
            href: this.href,
          }
        : {
            type: this.type,
          };
    },
    iconString() {
      if (!this.icon && !this.loading) return null;

      if (this.loading) return 'infinite-loading';

      return this.icon;
    },
  },
};
