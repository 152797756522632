
export default {
  name: 'Input',
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    className: {
      type: String,
      required: false,
      default: null,
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    computedValue: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      },
    },
    inputStyle() {
      if (this.error) {
        return {
          borderColor: '#d92001',
        };
      }
      return {};
    },
  },
};
